var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      { staticClass: "mb-2" },
      [
        !_vm.isXml && !_vm.readOnly
          ? _c(
              "b-row",
              { staticClass: "mb-1" },
              [
                _c(
                  "b-col",
                  { staticClass: "d-flex justify-content-end" },
                  [
                    _c("e-button", {
                      attrs: {
                        text: _vm.$t("Adicionar item"),
                        variant: "primary",
                      },
                      on: { click: _vm.onAddItem },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              [
                _c(
                  "FormulateForm",
                  { ref: "formulateOrderItems" },
                  [
                    _c("b-table", {
                      ref: "productTable",
                      staticClass: "bordered",
                      staticStyle: { "max-height": "500px !important" },
                      attrs: {
                        "show-empty": "",
                        striped: "",
                        fields: _vm.fields,
                        items: _vm.items,
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm.isXml
                            ? {
                                key: "thead-top",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-tr",
                                      [
                                        _c(
                                          "b-th",
                                          {
                                            attrs: {
                                              variant: "primary",
                                              colspan: "3",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "Produto da Nota Fiscal"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-th",
                                          {
                                            staticClass: "echope-product",
                                            attrs: {
                                              variant: "primary",
                                              colspan: "4",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("Produto eChope")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-th",
                                          {
                                            attrs: {
                                              variant: "primary",
                                              colspan: "2",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("Valores")) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              }
                            : null,
                          {
                            key: "cell(erpSku_name)",
                            fn: function (ref) {
                              var item = ref.item
                              var index = ref.index
                              return [
                                !_vm.readOnly &&
                                (!item.erpSku.sku || !item.erpSku.sku.name)
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "margin-right": "5px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              margin: "3px 0",
                                            },
                                          },
                                          [
                                            _c("e-button", {
                                              staticClass: "p-5",
                                              attrs: {
                                                icon: "plus",
                                                variant: "success",
                                                title:
                                                  _vm.$t("Criar novo produto"),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.createNewProduct(
                                                    item,
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticStyle: { width: "100%" } },
                                          [
                                            _c("FormulateInput", {
                                              staticClass: "ml-50",
                                              staticStyle: {
                                                width: "100%",
                                                "min-width": "200px",
                                                display: "inline-block",
                                              },
                                              attrs: {
                                                id:
                                                  "order-items-sku-searched-" +
                                                  index,
                                                type: "select-searchable",
                                                placeholder: _vm.$t(
                                                  "Digite para pesquisar"
                                                ),
                                                options: _vm.skusOptions,
                                                autofocus: "",
                                                instruction: _vm.$t(
                                                  "Digite pelo menos 3 numeros ou letras para iniciar a pesquisa"
                                                ),
                                              },
                                              on: {
                                                fetchSearch: _vm.fetchSearchSku,
                                                input: function ($event) {
                                                  return _vm.selectSku(
                                                    item,
                                                    item.erpSku.skuSearched
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.erpSku.skuSearched,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item.erpSku,
                                                    "skuSearched",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.erpSku.skuSearched",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        !_vm.readOnly ||
                                        (!_vm.readOnly && _vm.isXml)
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  margin: "3px 0",
                                                },
                                              },
                                              [
                                                _c("e-button", {
                                                  staticClass: "p-5",
                                                  attrs: {
                                                    icon: "trash",
                                                    variant: "danger",
                                                    title:
                                                      _vm.$t(
                                                        "Remover associação"
                                                      ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteSkuAssociation(
                                                        item.erpSku
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.readOnly && !item.erpSku.sku
                                          ? [_vm._v(" - ")]
                                          : [
                                              _c("e-product-router-link", {
                                                attrs: {
                                                  "sku-id": item.erpSku.sku.id,
                                                  "product-id":
                                                    item.erpSku.sku.productId,
                                                  "product-name":
                                                    item.erpSku.sku.name,
                                                  ean: item.erpSku.sku.ean,
                                                },
                                              }),
                                            ],
                                      ],
                                      2
                                    ),
                              ]
                            },
                          },
                          {
                            key: "cell(erpSku_prices)",
                            fn: function (row) {
                              return [
                                _c("p", [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("Preço Custo")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c("span", { staticClass: "pb-1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          row.item.erpSku
                                            ? row.item.erpSku.averagePrice
                                            : null,
                                          "-"
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("p", { staticClass: "mb-0" }, [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("Preço venda")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          row.item.erpSku
                                            ? row.item.erpSku.price
                                            : null,
                                          "-"
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(erpSku_unitOfMeasurement)",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      row.item.erpSku.sku
                                        ? row.item.erpSku.sku
                                            .unitOfMeasurement || "-"
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(erpSku_quantity)",
                            fn: function (row) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "column",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    !_vm.readOnly
                                      ? _c("FormulateInput", {
                                          staticClass: "text-right",
                                          staticStyle: { width: "80px" },
                                          attrs: {
                                            id:
                                              "order-items-quantity-" +
                                              row.index,
                                            type: "text-number",
                                            precision: 3,
                                            title: _vm.$t("Clique para editar"),
                                            validation: "required|min:0.001",
                                            "validation-messages": {
                                              required: _vm.$t("Obrigatório"),
                                              min: _vm.$t(
                                                "Deve ser maior que zero"
                                              ),
                                            },
                                          },
                                          on: {
                                            input: function (v) {
                                              return _vm.onInputErpQuantity(
                                                row.item
                                              )
                                            },
                                          },
                                          model: {
                                            value: row.item.erpSku.quantity,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                row.item.erpSku,
                                                "quantity",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "row.item.erpSku.quantity",
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("number")(
                                                row.item.erpSku.quantity,
                                                3
                                              )
                                            )
                                          ),
                                        ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(erpSku_unitValue)",
                            fn: function (row) {
                              return [
                                !_vm.validUnitValue(row.item)
                                  ? _c(
                                      "span",
                                      [
                                        _c("e-instructions", {
                                          attrs: {
                                            "container-class": "mr-0",
                                            title: _vm.$t("Validação"),
                                            target:
                                              "instruction_validation_" +
                                              row.index,
                                            instruction:
                                              "" +
                                              _vm.$t(
                                                "Valor unitário possui diferença de 5% em relação ao preço de custo do produto no sistema."
                                              ),
                                          },
                                        }),
                                        _c("br"),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isXml
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              row.item.totalValue /
                                                row.item.erpSku.quantity
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  : [
                                      !_vm.readOnly
                                        ? _c("FormulateInput", {
                                            staticClass: "text-right",
                                            staticStyle: { width: "120px" },
                                            attrs: {
                                              id:
                                                "order-items-unit_price-" +
                                                row.index,
                                              type: "text-number",
                                              currency: "R$",
                                              precision: 3,
                                              title:
                                                _vm.$t("Clique para editar"),
                                              validation: "required|min:0.01",
                                              "validation-messages": {
                                                required: _vm.$t("Obrigatório"),
                                                min: _vm.$t(
                                                  "Deve ser maior que 0.01"
                                                ),
                                              },
                                            },
                                            on: {
                                              input: function (v) {
                                                return _vm.onInputErpUnitValue(
                                                  row.item
                                                )
                                              },
                                            },
                                            model: {
                                              value: row.item.unitValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row.item,
                                                  "unitValue",
                                                  $$v
                                                )
                                              },
                                              expression: "row.item.unitValue",
                                            },
                                          })
                                        : _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    row.item.unitValue
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                    ],
                              ]
                            },
                          },
                          {
                            key: "cell(action)",
                            fn: function (row) {
                              return [
                                _c("e-grid-actions", {
                                  attrs: {
                                    "show-update": false,
                                    "show-delete": true,
                                  },
                                  on: {
                                    delete: function ($event) {
                                      return _vm.onRemoveItem(row.item)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }